<template>
  <div v-if="browser === 'SHIFT' && isProtectedRoute()">
    <LazyClassicNtpCommonError />
  </div>
  <div v-else class="ntp bg-onelaunch_ui_shades_50 dark:bg-radio_group_text_selected_dark">
    <Head>
      <slot name="headerScripts" />
      <Meta
        name="description"
        content="OneNews is the ideal tab page experience for OneLaunch browser users. Never miss local news, keep up with current weather and sports scores, and enjoy personalized content all in one place."
      />
    </Head>
    <!-- Google Tag Manager (noscript) -->
    <noscript>
      <iframe
        title="Google Tag Manager"
        src="https://www.googletagmanager.com/ns.html?id=GTM-WNFNMPW"
        height="0"
        width="0"
        style="display: none; visibility: hidden"
      ></iframe>
    </noscript>
    <!-- End Google Tag Manager (noscript) -->
    <div v-if="displayHeader" class="ntp__content text-center">
      <header>
        <slot name="headerSlot" />
      </header>
    </div>
    <slot name="bodySlot" />
    <slot v-if="browser !== 'SHIFT'" name="footerSlot" />
  </div>
</template>

<script setup>
  defineProps({
    displayHeader: {
      type: Boolean,
      default: true,
    },
  })

  const browser = useBrowser()
  const route = useRoute()
  const protectedRoutes = ['/contact/', '/advertise/', '/partnerships/', '/terms/', '/privacy-policy/']

  function isProtectedRoute() {
    return protectedRoutes.includes(route.path)
  }

  onMounted(() => {
    const faviconIcon = useRootFavicon()
    useHead({
      link: [
        {
          rel: 'icon',
          type: 'image/png',
          sizes: '32x32',
          href: faviconIcon,
        },
      ],
      script: [
        {
          children:
            "(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start': new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0], j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','GTM-WNFNMPW');",
        },
      ],
    })
  })
</script>

<style lang="scss" scoped>
  .ntp {
    min-height: 100vh;
    position: relative;

    &__content {
      padding-bottom: 39px;
    }
  }
</style>
<style lang="scss">
  @import 'bootstrap/scss/bootstrap.scss';
  @import '~/assets/stylesheets/common.scss';

  body {
    font-family:
      Inter,
      Roboto,
      -apple-system,
      BlinkMacSystemFont,
      'Segoe UI',
      Helvetica,
      Arial,
      sans-serif,
      serif,
      monospace;
  }
</style>
